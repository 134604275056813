import React, { useState, useEffect } from "react";

const FetchPHP = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("http://www.bartontechgroup.com/config/blogdb.php")
      .then((response) => response.text())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []); // <-- run the effect once, on component mount

  return (
    <div>
      <h2>PHP Response:</h2>
      {/* {data && <div>{data}</div>} */}
      {data && <div dangerouslySetInnerHTML={{ __html: data }} />}
    </div>
  );
};

export default FetchPHP;
