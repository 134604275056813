import React from "react";
import BlogForm from "../components/BlogForm";
import Table from "../components/BlogData";
import "../styles/blogs.scss";

const Blogs = () => {
  return (
    <div class="page">
      <div class="">
        <div class="header">
          <h2>Blogs (WIP)</h2>
          <Table />
        </div>
        <BlogForm />
        <div class="row">
          <div class="leftcolumn">
            <div class="card">
              <h2>TITLE HEADING</h2>
              <h5>Title description, Dec 7, 2017</h5>
              <div class="fakeimg">Image</div>
              <p>Some text..</p>
              <p>
                Sunt in culpa qui officia deserunt mollit anim id est laborum
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco.
              </p>
            </div>
            <div class="card">
              <h2>TITLE HEADING</h2>
              <h5>Title description, Sep 2, 2017</h5>
              <div class="fakeimg">Image</div>
              <p>Some text..</p>
              <p>
                Sunt in culpa qui officia deserunt mollit anim id est laborum
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco.
              </p>
            </div>
          </div>
          <div class="rightcolumn">
            <div class="card">
              <h2>About Me</h2>
              <div class="fakeimg">Image</div>
              <p>
                Some text about me in culpa qui officia deserunt mollit anim..
              </p>
            </div>
            <div class="card">
              <h3>Popular Post</h3>
              <div class="fakeimg">Image</div>
              <br />
              <div class="fakeimg">Image</div>
              <br />
              <div class="fakeimg">Image</div>
            </div>
            <div class="card">
              <h3>Follow Me</h3>
              <p>Some text..</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
