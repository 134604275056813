import React from "react";
import Container from "react-bootstrap/Container";
import "./navbar.scss";
import IconFull from "../../assets/svg/logofull";
import Icon from "../../assets/svg/logoicon";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const NavbarComp = () => {
  return (
    <Navbar expand="md" className="fixed-top">
      <Container fluid className="nav-text">
        <Navbar.Brand href="/">
          <IconFull className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse>
          <Nav className="nav-items-top">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/portfolio">Portfolio</NavLink>
            <NavLink to="/learn">Learn</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/blogs">Blogs</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComp;
