import React from "react";
import "../styles/secondary.scss";
import { Container } from "react-bootstrap";

const Learn = () => {
  return (
    <div class="secondary-page page">
      <Container>
        <h1>Learn</h1>
      </Container>
    </div>
  );
};

export default Learn;
