import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import logo1 from "../../assets/logo1.PNG";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./blogform.scss";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function BlogForm() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="btn create-blog-btn" onClick={handleShow}>
        Create a post
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Blog Post Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            method="POST"
            action="your-action-url"
            enctype="multipart/form-data"
          >
            <div class="form-group">
              <label for="title">Title</label>
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                placeholder="Enter title"
              />
            </div>

            <div class="form-group">
              <label for="author">Author</label>
              <input
                type="text"
                class="form-control"
                id="author"
                name="author"
                placeholder="Enter author's name"
              />
            </div>

            <div class="form-group">
              <label for="content">Content</label>
              <textarea
                class="form-control"
                id="content"
                name="content"
                rows="3"
              ></textarea>
            </div>

            <div class="form-group">
              <label for="file">Upload Image</label>
              <input
                type="file"
                class="form-control-file"
                id="file"
                name="file"
              />
            </div>

            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BlogForm;
