import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import logo1 from "../../assets/logo1.PNG";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="scroll-section footer-section">
      <Container>
        <Row className="">
          <Col className="p-3">
            <Nav className="nav-items-footer">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/portfolio">Portfolio</NavLink>
              <NavLink to="/learn">Learn</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/blogs">Blogs</NavLink>
            </Nav>
          </Col>
          <Col className="p-3">
            <Nav className="nav-items-footer">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/portfolio">Portfolio</NavLink>
              <NavLink to="/learn">Learn</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/blogs">Blogs</NavLink>
            </Nav>
          </Col>
          <Col className="p-3">
          <Nav className="nav-items-footer">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/portfolio">Portfolio</NavLink>
              <NavLink to="/learn">Learn</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/blogs">Blogs</NavLink>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
